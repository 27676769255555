import React , {Component } from 'react';
import Services from '../Sections/Services';
import Features from '../Sections/Features';
import Counter from '../Sections/Counter';
import Pricing from '../Sections/Pricing';
import Client from '../Sections/Client';
import Faq from '../Sections/Faq';
import QuienesSomos from '../Sections/QuienesSomos';
import Contact from '..//Sections/Contact';
import { Link } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/action';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import Ciudades from '../Sections/Ciudades';

class HomeOne extends Component{

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
        this.openModal = this.openModal.bind(this);
    }
 
    openModal() {
        this.setState({ isOpen: true })
    }
  
    componentDidMount() {
        if(this.props.islight === true)
        {
          this.props.UpdateLight();
        }
       }
    
    render(){

        return(
            <AUX>

<section className="home-bg-color" id="home" style={{ paddingTop: '100px' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h1 className="home-title" style={{ color: 'white', fontWeight: 'bold' }}>#1 Real Estate Platform</h1>
                                <ul className="home-subtitle mx-auto" style={{ textAlign: 'left', color: 'white' }}>
                                    <li>Datos de Mercado: Decisiones clave con tendencias y características de las propiedades</li>
                                    <li>Property Explorer: Descubre el valor REAL potencial, mínimo y máximo de tu propiedad</li>
                                    <li>Al Mercado: Utiliza AI para definir el mejor mercado para tu producto</li>
                                </ul>
                            </div>
                            <div className="col-md-6 d-flex flex-column justify-content-center" style={{ minHeight: '100px' }}>
                                <div className="email-registration-container " style={{ width: '100%', textAlign: 'center' }}>
                                    <input type="email" placeholder="Ingresa tu correo electrónico" style={{ width: '80%', borderRadius: '5px', border: '1px solid #ccc', height: '40px', padding: '0 15px' }} />
                                    <button className="btn btn-custom" style={{ width: '80%', marginTop: '10px' }}>Regístrate</button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img src="images/macbooka.png" alt="" className="img-fluid center-block home-dashboard" />
                            </div>
                        </div>
                    </div>
                </section>




            <Features />
            <Counter />
            <Services />
            <Pricing />
            <Client />
            <Faq />
            <Ciudades />
            <QuienesSomos />
            <Contact />
        </AUX>
        );
    }
}


const mapStatetoProps = state => {
    return {
        islight: state.ui_red.islight
    };
}

const mapDispatchtoProps = dispatch => {
    return {
        UpdateLight: () => dispatch({ type: actionTypes.ISLIGHT, value: true })
    };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(HomeOne);

