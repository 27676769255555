import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';

class Pricing extends Component{
    
    render(){
        return(
            <AUX>
                <section className="section bg-light" id="pricing" style={{paddingTop: '20px', paddingBottom: '80px'}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="text-center">
                                    <h3 className="section-title pt-5">Planes ideales para ti</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-4">
                            <div className="price-box px-4 pt-4 bg-white mt-4 price-box-container" style={{ boxShadow: '2px 8px 8px rgba(0, 0, 0, 0.1)', borderRadius: '12px', width: 'auto', height: '800px'}}>
                                <div className="plan-name mt-4" style={{paddingBottom: '42px' }}>
                                        <h6 style={{paddingBottom: '25px', fontSize: '35px' }}>Plan Panorama</h6>
                                        <p style={{ marginBottom: '0' }}>Muestre una descripción general histórica de precios, comprenda la distribución y las tendencias por segmento y acceda a análisis demográficos, comparaciones regionales y más.</p>                                        
                                    </div>
                                    <div className="plan-name mt-4" style={{paddingBottom: '42px' }}>
                                        <span style={{ fontWeight: 'bold', fontSize: '40px', color: '#6774DF' }}>Free</span>
                                    </div>

                                    <ul style={{ paddingLeft: '18px', lineHeight: '2em'}}>
                                        <li>Visión General del Mercado</li>
                                        <li>Distribución de Propiedades por Segmento</li>
                                        <li>Tendencias de Precios por Segmento</li>
                                        <li>Comparación Regional</li>
                                        <li>Estacionalidad</li>                                        
                                        <li>Tendencias de Precios por Segmento</li>
                                    </ul> 
                                    
                                    
                                    <div className="mt-5 mb-20 d-flex justify-content-center align-items-center" style={{ marginBottom: '50px' }}>
                                        <Link to="registro" className="btn btn-custom btn-rounded" style={{ marginTop: '-10px', marginBottom: '10px' }}>Suscríbete ahora</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="price-box px-4 pt-4 bg-white mt-4 price-box-container" style={{ boxShadow: '2px 8px 8px rgba(0, 0, 0, 0.1)', borderRadius: '12px', width: 'auto', height: '800px'}}>                                    
                                    <div className="plan-name mt-4">
                                        <h6 style={{paddingBottom: '20px', fontSize: '35px' }}>Plan Horizonte</h6>
                                        <p style={{ marginBottom: '0',  }}>Proporciona un conjunto aún más completo de herramientas, respaldadas por datos verificados para tomar decisiones informadas en el mercado inmobiliario.</p>                                       
                                        
                                    </div>
                                    <div className="plan-name mt-4">    
                                        <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Ideal para:</p>                                        
                                        <p style={{ marginBottom: '0' }}>Agente de Bienes Raíces</p>
                                    </div>
                                    <div className="plan-name mt-4">    
                                    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Desde:</p>
                                        <div style={{paddingBottom: '20px' }}>
                                            <span style={{ fontWeight: 'bold', fontSize: '40px', color: '#6774DF' }}>$899</span>
                                            <span style={{ fontSize: '20px' }}>por mes</span>
                                        </div>
                                    </div>
                                    <ul style={{ paddingLeft: '18px', lineHeight: '2em' }}>
                                        <li>Todo lo del Plan Panorama</li>
                                        <li><strong style={{ color: '#6774DF' }}>1 sesion</strong>de consultoria</li>
                                        <li>Distribución de créditos</li>
                                        <li>Análisis Detallado del Mercado</li>
                                        <li>Demanda y Salario</li>
                                        <li>Profesiones y Oficios</li>
                                    </ul>
                                    <div className="mt-5 mb-20 d-flex justify-content-center align-items-center" >
                                        <a href="https://teseodata.recurly.com/subscribe/planhorizonte2023?currency=MXN" className='btn btn-custom btn-rounded' >Suscríbete ahora</a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-4">
                            <div className="price-box px-4 pt-4 bg-white mt-4 price-box-container" style={{ boxShadow: '2px 8px 8px rgba(0, 0, 0, 0.1)', borderRadius: '12px', width: 'auto', height: '800px'}}>                                    
                                    <div className="plan-name mt-4">
                                        <h6 style={{paddingBottom: '20px', fontSize: '35px'}}>Plan Vision</h6>
                                    
                                        <p style={{ marginBottom: '0' }}>Proporciona una versión mucho más descriptiva, analítica y personalizada, proporcionando acceso a funcionalidades avanzadas.</p>                                       
                                        
                                    </div>
                                    <div className="plan-name mt-4">    
                                        <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Ideal para:</p>                                        
                                        <p style={{ marginBottom: '0' }}>Constructor / Desarrollador</p>
                                    </div>
                                    <div className="plan-name mt-4">    
                                    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Desde:</p>
                                        <div style={{paddingBottom: '20px' }}>
                                            <span style={{ fontWeight: 'bold', fontSize: '40px', color: '#6774DF' }}>$1,199</span>
                                            <span style={{ fontSize: '20px' }}>  por mes</span>
                                        </div>
                                    </div>
                                    <ul style={{ paddingLeft: '18px', lineHeight: '2em' }}>
                                        <li>Todo lo del Plan Horizonte</li>
                                        <li>Optimización del espacio</li>
                                        <li>Personalización completa</li>
                                        <li>Incluye <strong style={{ color: '#6774DF' }}>1 sesión mensual de consultoría</strong></li>
                                        <li>1 informe inmobiliario</li>
                                        <li>Acceso completo a la plafatorma</li>
                                    </ul>
                                    <div className="mt-5 mb-20 d-flex justify-content-center align-items-center" >
                                        <a href="https://teseodata.recurly.com/subscribe/planvision2023?currency=MXN" className='btn btn-custom btn-rounded' >Suscríbete ahora</a>
                                    </div>
                                 </div>
                            </div>

                            {/* Separador visual */}
                            <div className="col-lg-12 text-center mt-4">
                            <hr style={{ border: '1px solid #ddd' }} />
                            <h2 className="section-title pt-5">Servicios Data Premium</h2>
                            </div>

                            <div className="container d-flex flex-wrap">
                            <div className="col-md-4 d-flex">
                                <div className="price-box px-4 pt-4 pb-2 bg-white text-left mt-4 price-box-container d-flex flex-column flex-grow-1" style={{ boxShadow: '2px 8px 8px rgba(0, 0, 0, 0.1)', borderRadius: '12px', display: 'flex' }}>
                                    <div className="plan-name mt-4">
                                        <h6 style={{ paddingBottom: '10px', lineHeight: '1.2em' }}>Factibilidad Financiera para el Mercado Inmobiliario</h6>
                                    </div>
                                    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Te ofrecemos un análisis robusto y fácil de usar que evalúa la viabilidad económica de tus proyectos inmobiliarios.</p>                                     
                                    <ul style={{ paddingLeft: '18px', flexGrow: '1' }}>
                                        <li className='pb-2'>Evaluación detallada de costo-beneficio.</li>
                                        <li className='pb-2'>Análisis de Riesgos Financieros para la Planificación Estratégica</li>
                                        <li className='pb-2'>Proyecciones de Rendimiento a Largo Plazo</li>
                                        <li className='pb-2'>Informes Personalizables para Presentaciones Efectivas</li>
                                    </ul>
                                    
                                </div>
                            </div>

                            <div className="col-md-4 d-flex">
                                <div className="price-box px-4 pt-4 pb-2 bg-white text-left mt-4 price-box-container d-flex flex-column flex-grow-1" style={{ boxShadow: '2px 8px 8px rgba(0, 0, 0, 0.1)', borderRadius: '12px', display: 'flex' }}>
                                    <div className="plan-name mt-4">
                                        <h6 style={{ paddingBottom: '10px', lineHeight: '1.2em' }}>Optimización de productos para el mercado inmobiliario</h6>
                                    </div>
                                    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Te ofrecemos un análisis para mejorar la eficiencia operativa y la rentabilidad de tus proyectos.</p>                                     
                                    <ul style={{ paddingLeft: '18px', flexGrow: '1' }}>
                                        <li className='pb-2'>Análisis Detallado de la Eficiencia Espacial</li>
                                        <li className='pb-2'>Recomendaciones para la Selección de Materiales y Acabados</li>
                                        <li className='pb-2'>Estrategias de Optimización de Costos</li>
                                        <li className='pb-2'>Personalización Según las Tendencias del Mercado</li>
                                    </ul>
                                    
                                </div>
                            </div>

                            <div className="col-md-4 d-flex">
                                <div className="price-box px-4 pt-4 pb-2 bg-white text-left mt-4 price-box-container d-flex flex-column flex-grow-1" style={{ boxShadow: '2px 8px 8px rgba(0, 0, 0, 0.1)', borderRadius: '12px', display: 'flex' }}>
                                    <div className="plan-name mt-4">
                                        <h6 style={{ paddingBottom: '10px', lineHeight: '1.2em' }}>Explorador de Propiedades</h6>
                                    </div>
                                    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Ofrecemos un análisis por zona o propiedad, para entender la dinámica del mercado inmobiliario y apoyar sus decisiones de inversión.</p>                                     
                                    <ul style={{ paddingLeft: '18px', flexGrow: '1' }}>
                                        <li className='pb-2'>Valoración integral de las características del inmueble</li>
                                        <li className='pb-2'>Comparación completa con propiedades similares en el mercado</li>
                                        <li className='pb-2'>Registro detallado de las tendencias actuales del mercado</li>
                                        <li className='pb-2'>Análisis Estratégico del Mercado Inmobiliario y Evaluación de Riesgos</li>
                                    </ul>
                                    <div className="mt-2 d-flex justify-content-center align-items-end">
                                        <a href="/images/PropertyExplorer.pdf" className="btn btn-custom btn-rounded btn-fixed-width" target="_blank" style={{ marginBottom: '20px' }}>Ver Demo</a>
                                    </div>

                                </div>
                            </div>
                        </div>


                            {/* Separador visual */}
                            <div className="col-lg-12 text-center mt-4">
                            <hr style={{ border: '1px solid #ddd' }} />
                            <h2 className="section-title pt-5"></h2>
                            </div>
                            <div className="" style={{height:"100%", width:"100%"}}>
                            <div className="price-box px-4 pt-4 pb-2 bg-white text-center mt-4 price-box-container" style={{ boxShadow: '2px 8px 8px rgba(0, 0, 0, 0.1)', borderRadius: '12px', height:"auto", width:"auto"}}>
                            <div className="plan-name mt-4 d-flex justify-content-center align-items-center">
                                <h6 style={{paddingBottom: '10px', lineHeight:'1.2em' }}>DEMO</h6>
                            </div >
                                <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Explora DatAlpine, tu demo gratuita para tomar decisiones estratégicas sobre bienes raíces</p>                                     
                                
                                {/* <div className="mt-2">
                                <Link to="#" className="text-info underline-left">Más Información</Link>
                                </div> */}
                               <div className="mt-2 mb-10 d-flex justify-content-center align-items-center" style={{ marginBottom: '20px' }}>
                <Link to="demo" className="btn btn-custom btn-rounded btn-fixed-width" style={{ marginTop: '5px', marginBottom: '5px' }}>Iniciar Demo</Link>
            </div>
            <div className="mt-2 d-flex justify-content-center align-items-end" style={{ marginBottom: '20px' }}>
                <Link to="contacto_ventas" className="btn btn-custom btn-rounded btn-fixed-width" style={{ marginTop: '15px' }}>Contacto con Ventas</Link>
            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
           </AUX>
        );
    }
}

export default Pricing;   