import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';

class Client extends Component{
    
    render(){
        return(
            <AUX>
                <section className="section bg-gray" id="client" style={{paddingTop: '40px', paddingBottom: '40px'}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="text-center">
                                    <h2 className="section-title pt-5">Testimonios y Reseñas</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4">
                            <div className="testimonial-box">
                            <div className="testimonial-desc bg-light p-4">
                                <p className="text-center text-muted"><span>"</span>DatAlpine ha transformado la forma en que evaluamos y desarrollamos proyectos. Con su análisis en profundidad, hemos identificado oportunidades que antes no había considerado. ¡Una herramienta esencial para cualquier constructor!<span>"</span></p> 
                                <p className="text-center mb-0 quote-icon text-custom">
                                <img src="images/luisg.jpg" alt="Luis G." className="custom-image" style={{ width: '100px', height: '100px', borderRadius: '50%' }}/>
                                </p>
                                <p className="text-center text-muted nombre"><span></span>Luis G.<span></span></p>
                            </div>
                            <div className="mt-4 testi-img">
                            </div>
                            </div>

                            </div>
                            <div className="col-md-4">
                                <div className="testimonial-box">
                                    <div className="testimonial-desc bg-light p-4">
                                        <p className="text-center text-muted"><span>"</span>Como inversionista, siempre busco tomar decisiones respaldadas por datos sólidos. Los informes me han proporcionado información valiosa sobre las tendencias del mercado y, de esta manera, reconocer las oportunidades de inversión.<span>"</span></p>
                                        <p className="text-center mb-0 quote-icon text-custom">
                                        <img src="images/joelf.jpg" alt="Luis G." className="custom-image" style={{ width: '100px', height: '100px', borderRadius: '50%' }}/>
                                        </p>
                                        <p className="text-center text-muted nombre"><span></span>Joel F.<span></span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="testimonial-box">
                                    <div className="testimonial-desc bg-light p-4">
                                        <p className="text-center text-muted"><span>"</span>La clave para destacar en el mercado inmobiliario es conocer a fondo las necesidades de los clientes y el entorno. DatAlpine simplifica este proceso, proporcionándonos información precisa y actualizada.<span>"</span></p>
                                        <p className="text-center mb-0 quote-icon text-custom">
                                        <img src="images/marias.jpg" alt="Luis G." className="custom-image" style={{ width: '100px', height: '100px', borderRadius: '50%' }}/>
                                        </p>
                                        <p className="text-center text-muted nombre"><span></span>Maria S.<span></span></p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
           </AUX>
        );
    }
}

export default Client;   