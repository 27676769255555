import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import { FaFlag } from 'react-icons/fa'; // Importar el icono de la bandera de México
import { IoIosMedal } from 'react-icons/io';





class QuienesSomos extends Component {

    render() {
        return (
            <AUX>
                <section className="section" id="quienessomos" style={{ paddingTop: '20px', paddingBottom: '10px' }}>

                    <div className="container" style={{ paddingBottom: '50px' }}>
                        <div className="row vertical-content">
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <div className="features-box">
                                    <div className="features-desc" >
                                        <h3 className='title-quines-somos' >Quienes Somos</h3>
                                        <p className="pt-2 txt-quines-somos text-left">DatAlpine es un producto de TESEO DATA LAB, un equipo global de científicos de datos. Con más de 18 proyectos inmobiliarios y trayectoria en México, Japón, Estados Unidos, Canadá, Suiza y Alemania. Expertos comprometidos con la transformación de los datos en estrategias efectivas.</p>
                                        <p className="pt-2 txt-quines-somos text-left">Como desarrolladores de software y expertos en analítica de datos e inteligencia artificial en la industria, estamos comprometidos a brindar soluciones precisas y efectivas que generen resultados concretos para nuestros clientes.</p>
                                        
                                        <IoIosMedal style={{ marginRight: '10px' }} /> Certificaciones:
                                        <p >CIRA Canadá Certificate <br /> Certificado de Negocios de Alberta</p>
                                        <FaFlag style={{ marginRight: '10px' }} /> Con presencia en los siguientes países:
                                        <div>
                                        <p>México y Canadá</p>
                                        </div>
                                    </div>
                                    <div className="features-desc" style={{ paddingTop: '10px', }}>
                                        <a href="https://teseodata.com/" className="btn-quines-somos2">Conozca nuestro sitio web</a>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6 pt-4">
                                <img src="images/workteam.jpg" alt="" className="img-fluid rounded" />
                            </div>
                        </div>
                    </div>
                    {/* Fila de imágenes */}
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div> <img src="images/valdepenas.png" alt="Valdepenas" className="custom-image" style={{  height: '75px'}}/></div>
                            <div> <img src="images/sherwin.png" alt="Sherwin" className="custom-image" style={{height: '90px'}}/></div>
                            <div> <img src="images/logel.png" alt="Logel" className="custom-image" style={{ height: '60px'}}/></div>
                            <div> <img src="images/stratto.png" alt="Stratto" className="custom-image" style={{ height: '70px' }}/></div>
                            <div> <img src="images/loscantos.png" alt="LosCantos" className="custom-image" style={{ height: '80px' }}/></div>
                            <div> <img src="images/ampipuebla.png" alt="Ampi" className="custom-image" style={{ height: '85px' }}/></div>
                            <div> <img src="images/linden.png" alt="linden" className="custom-image" style={{ height: '80px' }}/></div>
                            <div> <img src="images/save.png" alt="save" className="custom-image" style={{ height: '100px' }}/></div>
                            
                            {/* Repite para las demás imágenes */}
                        </div>
                    {/*
                    <div className='container-quines-somos' style={{boxShadow: '2px 8px 8px rgba(0, 0, 0, 0.1)'}}>
                        <img className='container-img' src="images/Logo.png" style={{paddingBottom: '5px'}}/>
                        <p className='container-txt'>Tu socio en decisiones inmobiliarias</p>
                        <a href="#contact" className='btn-quines-somos'>Contáctanos</a>
                    </div>
                     */}
                </section>
            </AUX>
        );
    }
}
export default QuienesSomos; 
